/* =====================commmon CSS Elements =======================*/
@font-face {
  font-family:GilroyBold ;
  src:  url(./Components/font/Gilroy-Bold.ttf) ;
 }
 @font-face {
  font-family:GilroyExtraBold ;
  src:  url(./Components/font/Gilroy-Extrabold.ttf) ;
  font-weight: bold;
 }
 @font-face {
  font-family:GilroyHeavy ;
  src:  url(./Components/font/Gilroy-Heavy.ttf) ;
  font-weight: bold;
 }
 @font-face {
  font-family: GilroyLight;
  src: url(./Components/font/Gilroy-Regular.ttf) ;
  font-weight: bold;
 }
 @font-face {
  font-family: GilroyRegular;
  src: url(./Components/font/Gilroy-Regular.ttf);
  /* font-weight: bold; */
}
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: GilroyRegular;
  background-color: #FAFAFA;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.container {
  max-width: 85%;
  width: 100%;
  margin: 0 auto;
}
.pt {
  margin-top: 2rem;
}
.pb {
  margin-bottom: 2rem;
}
.main_heading {
  /* font-size: 1.2rem;
  font-weight: 900;
  text-align:left; */
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: 700; */
  text-align: left;
  font-family: GilroyRegular;
}
.pl{
  margin-left: .6rem;
}
.loader{
  /* width:100vh; */
  height:100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: auto;
}
.image-loader img{
  background-color: white;
  padding-top: 250px;
  /* display: flex; */
  width:35%;
  position: absolute;
  top: 30%;
  height: auto;
  margin: auto;
  justify-content: center;
  align-items: center;
  left: 40%;
  transform: translate(-30%, -50%);
}

@media only screen and (max-width: 480px) {
  .main_heading {
   font-size: 18px;
    text-align: left;
    font-family: GilroyRegular;
  }
}

