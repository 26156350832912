
.text-left{
    font-size:1.1rem;
    font-weight: 500;
}
.ContainerF {
    margin: 0 auto;
    max-width: 100%;
    box-sizing: border-box;
    margin-top: 50px;
    width: 100%;
}
.row{
    display:flex;
    flex-wrap:wrap;
    padding: 45px 0px;
    background-color: #4D5882;
    justify-content: space-between;
    width:100%;
}
.footer-col{
    padding:0 15px;
    width:33%;
}
.Row0 {
    /* margin-left: 5%; */
    background-color: #4D5882;
    color: #FFFFFF;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
    /* gap: 340px; */
    /* margin-left: 4px; */
}

.Row0 div a {
    text-decoration: none;
    color: #FFFFFF;
}

.icons {
    align-items: end;
    width: 20px;
    height: 20px;
    /* color: white; */
}

.icons-div {
    display: flex;
    gap: 13px;
    justify-content: center;
    margin-top: 7px;
}
.play-div {
    display: flex;
    gap: 13px;
    justify-content: flex-end;
    margin-top: 7px;
    margin-right: 40px;
}
.apps{
    display: flex;
    flex-direction: column;
}
.download-mobile-apps {
   /* margin-left: 200px; */
   white-space: nowrap;
   display: flex;
   justify-content: flex-end;
  }
.Row1 {
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: #434F7B;
    text-align: center;
    color: #FFFFFF;
}
.icons-circle{
    width:40px;
    height:40px;
    border-radius: 25px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .Row0 div {
    flex: 1; 
} */
.itemStyle {
    color: #EBEDF1;
    /* font-weight: bold; */
    /* font-size: 1.1rem; */
    width:33%;
    margin-left: -20px;
}
.icon-style{
    color: #EBEDF1;
    font-size: 15px;
    margin-right: 5px;
    padding-top: 3px;
}
.divStyle {
    display: flex;
    margin-top: 7px;
    /* font-weight: 600; */
}

.div-links {
    /* font-weight: bold; */
    text-decoration: none;
    color: #EBEDF1;
    display: flex;
    justify-content: center;
}

.call {
    margin-top: 2px;
    color: #2D307A;
    margin-right: 7px;
    /* font-weight: bold; */
}
.Row3 {
    background-color: #4D5882;
    color: #FFFFFF;
}
.copyright{
    font-size: 14px;
    font-weight: 600;
    color:#FFFFFF;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}
@media screen and (max-width: 768px) {
    .ml{
        margin-left: 25%;
    }
    .icons-div {      
        justify-content: flex-end;
        align-items: flex-end;
    }
    .div-links {
        /* font-weight: bold; */
        text-decoration: none;
        color: #EBEDF1;
        display: flex;
        justify-content: flex-end;
    }
    .footer-col{
        padding:0 15px;
        width:50%;
        
    }
    .ContainerF 
    {
        margin: 0 auto;
        max-width: 100%;
        box-sizing: border-box;
        margin-top: 50px;
        width: 100%;
        margin: 0 auto;
    }
    .Row0 {
        margin-left: 5%;
         display: flex;
        justify-content: space-between; 
        padding-top: 35px;
        padding-bottom: 35px;
        /* gap: 135px; */
        margin-left: 4px;
        padding-left: 20px;
        padding-right: 20px;
    } 
    .itemStyle {
        color: #EBEDF1;
        width:50%;
    }
    /* .icons-div {
        margin: auto;
        align-items: center;
        display: flex;
        gap: 13px;
        justify-content: center;
        padding-top: 20px;
    } */
    /* .Row0 div {
        flex: auto;
    } */
    .icons {
        margin-right: 0;
        /* color: white; */
    }
    .third-div{
        width: 100%;
    align-items: center;
    justify-content: center;
    width:100%;
    }
    .play-div {
        margin-right: 1px;
    }
    
}
@media screen and (max-width: 1024px) {
    .div-links {
        /* font-weight: bold; */
        text-decoration: none;
        color: #EBEDF1;
        display: flex;
        justify-content: flex-end;
    }
    .ml{
        margin-left: 25%;
    }
    .third-div{
        width: 100%;
    align-items: center;
    justify-content: center;
    }
    .footer-col{
        padding:0 15px;
        width:50%;     
    }
    .ContainerF 
    {
        margin: 0 auto;
        max-width: 100%;
        box-sizing: border-box;
        margin-top: 50px;
        width: 100%;
        margin: 0 auto;
    }
    .Row0 {
        margin-left: 5%;
         display: flex;
        justify-content: space-between; 
        padding-top: 35px;
        padding-bottom: 35px;
        /* gap: 135px; */
        margin-left: 4px;
        padding-left: 20px;
        padding-right: 20px;
    } 
    .itemStyle {
        color: #EBEDF1;
        /* font-size: 15px; */
        width:50%;
    }
    .icons-div {
        margin: auto;
        align-items: flex-end;
        display: flex;
        gap: 13px;
        justify-content: flex-end;
        padding-top: 20px;
    }
    /* .Row0 div {
        flex: auto;
    } */
    .icons {
        margin-right: 0;
        /* color: white; */
    }
    .play-div {
        margin-right: 1px;
    }
}
@media screen and (max-width: 468px) {
  .third-div{
    margin-top: 15px;
  }
}