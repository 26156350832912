.products_grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
}

/* .swiperslide {
  width: 4% !important; 
} */
.skeleton-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.products_grid_Cat {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}

.products_grid_item {
  background: #F9E8DB;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 14rem;
}

.products_grid_item:hover {
  cursor: pointer;
}

.heading-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-box a {
  text-decoration: none;
}

.button-Style {
  align-items: center;
  justify-content: center;
  display: flex;
}

.see-all {
  background-color: #F8F8FF;
  color: midnightblue;
  display: block;
  border: none;
  cursor: pointer;
}

.products_grid_item img {
  padding-bottom: 10px;
  max-width: 100px;
  height: 100px;
}

.products_grid_item h6 {
  font-size: 1.1rem;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
}

.Exclusive {
  /* width: 254px; */
  border-radius: 25px;
  /* background-color:lightgray; */
}

.Exclusive_p {
  /* width: 270px; */
  border-radius: 25px;
}

.Exclusive:hover {
  cursor: pointer;
}

.popular-exclusive {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
}

.exclusive_product {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 13px;
}

.offers {
  /* width:100%; */
  height: 60vh;
  display: flex;
  /* margin:auto; */
  /* padding:0px 140px; */
  margin-bottom: 10px;
  background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
}

.offers-left {
  display: flex;
  margin-right: -250px;
  /* line-height: 0; */
  /* margin-bottom:50px; */
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;

}

.offers-left h1 {
  color: #171717;
  font-size: 80px;
  font-weight: 600;
}

.offers-left p {
  color: #171717;
  font-size: 22px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}

.offers-left button {
  width: 282px;
  height: 70px;
  border-radius: 35px;
  background: #ff4141;
  border: none;
  color: white;
  font-size: 22px;
  font-weight: 500;
  margin-top: 30px;
  margin-left: 16px;
  cursor: pointer;
}

.offers-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 50px; */
}

.offers-right img {
  width: 400px;
  height: 390px;
  margin-left: 550px;
}

.Exclusive_item {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 15px; */
  /* padding-bottom: 35px; */
  min-height: 17em;
  /* width: 16em; */
  border-radius: 25px;
  border: 1px solid gainsboro;
}
.order-list{
  margin: auto;
    display: flex;
    justify-content: center;
    width: 70%;
    flex-direction: column;
}
.Exclusive_item_p {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 15px; */
  /* padding-bottom: 35px; */
  min-height: 17em;
  /* width: 13.7em; */
  border-radius: 25px;
  border: 1px solid gainsboro;
}

.Exclusive_item img {
  display: block;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-top: -5px;
  height:100px;
}

.centered-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
}

.lazyload {
  height:45px !important;
  width:45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Exclusive_item_p img {
  max-width: 80%;
  display: block;
  margin-top: 10px;
  margin-bottom: 15px;
  height: 5rem;
  margin-top: -5px;
}

.Exclusive_itemp {
  font-size: 1.1rem;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  height: 60px;
  margin-bottom: 10px;
  overflow: hidden; 
  text-overflow: ellipsis;
  display: block;        
}

.prices {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.I_prices {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.item-prices {
  display: flex;
  gap: 10px;
  line-height: 2;
}

.item img {
  border-radius: 15px;
  margin-top: 10px;
}

.item_price-new {
  color: #374151;
  font-size: 14px;
  font-weight: 100;
}

.item_price-old {
  color: #1E1E1E;
  font-size: 14px;
  font-weight: 500;
  text-decoration: line-through;
}

.Exclusive_price-new {
  color: #1E1E1E;
  font-size: 14px;
  font-weight: 400;
  /* margin-right: 200px; */
  flex-wrap: nowrap;
  margin-left: 2px;
}

.Exclusive_price-new_I {
  color: #1E1E1E;
  font-size: 14px;
  font-weight: 400;
  /* margin-right: 200px; */
  flex-wrap: nowrap;
  margin-left: 2px;
}

.addtocard {
  width: 40px;
  height: 40px;
  border-radius: 18px;
  background-color: #F17E2A;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 160.5px; */
  margin-top: -40px;
  margin-left: auto;
}

.addtocard p {
  font-size: 26px;
  font-weight: 400;
  margin-top: 5px;
}
.required-errors{
  margin-left: 5px;
    margin-top: -10px;
    color: red;
    font-size: 13px;
}
.addtocard_I {
  width: 40px;
  height: 40px;
  cursor:pointer;
  border-radius: 18px;
  background-color: #F17E2A;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 160.5px; */
  margin-top: -40px;
  margin-left: auto;
}
.Order-loader {
  border: 5px solid #f3f3f3; 
  border-top: 5px solid #F18637; 
  border-radius: 50%; 
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}
.loader-span{
  margin-top:5px;
  display: block; 
  text-align: center;
}
.swiper-button-next{
  color:#495178 !important;
  opacity: 0.5;
}
.swiper-button-prev{
  color:#495178 !important;
  opacity: 0.8;
}
.swiper-button-prev:after{
  font-size: 25px !important;
}
.swiper-button-next:after {
  font-size: 25px !important;
}
.loader-div{
  width: 100%;
  margin-top: -20px;
    height: 100vh;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.rounded-loader{
  width: 35px !important;
    height: 40px !important;
    border-radius: 100%;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 126, 42) rgb(241, 126, 42) transparent;
    border-image: initial;
    display: inline-block;
    animation: 0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip;
}
.addtocard_I p {
  font-size: 26px;
  font-weight: 400;
  padding-top: 5px;
}

.Exclusive_old {
  color: #1E1E1E;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
  /* text-decoration: line-through red; */
}

.Exclusive_old_I {
  color: #1E1E1E;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
  /* text-decoration: line-through red; */
}

.quantity-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  border-radius: 5px;
  padding: 5px 20px;

  span {
    background-color: white;
    text-align: center;
    font-weight: 300;
    padding: 10px;
    width: 35px;
    color: #47527E;
    border-radius: 15px;
    border: 1px solid #F4F5F4;
  }
}

.product-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  border-radius: 5px;
  padding: 5px;
  padding-top: 20px;

  span {
    background-color: white;
    text-align: center;
    font-weight: 300;
    padding-top: 3px;
    width: 35px;
    height: 35px;
    color: #47527E;
    border-radius: 15px;
    border: 1px solid #F4F5F4;
  }
}

.button-1 {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 26px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  margin-top: -5px;
  background-color: #FAFAFA;
}

.button-2 {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 26px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: #F17E2A;
  background-color: #FAFAFA;
}

.button-2:hover {
  background-color: #E9E9E9;
}

.anoucement {
  background: #FAFAFA;
  color: #F17E2A;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  font-size: 1rem;
}

.anoucement-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
}

.text1 span {
  margin-right: 3rem;
  white-space: nowrap;
  display: inline-block;
}

.text2 span {
  margin-right: 4rem;
  white-space: nowrap;
  display: inline-block;
}

.Rslider {
  display: flex;
  flex-direction: row;
  /* margin-top: 45px; */
  /* justify-content: center;
    align-items: center; */
  /* padding-bottom: 24%; */
}

.text2 span:last-child {
  margin-right: 1rem;
}

.swiperImg {
  height: 16.5rem;
  width: 100%;
}

.subcategory {
  color: #F17E2A;
  background-color: #F2F3F2;
  border-radius: 2.2rem;
  margin-bottom: 10px;
  border: none;
  height: 32px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.subcat {
  background-color: #F6BC92;
  color: #FFFFFF;
}

.linkstyle {
  text-decoration: none;
  color: #171717;
}

.section6 {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  /* background-color: white; */
  border-radius: 1.4rem;
  border: 1px solid gainsboro;
}

.product {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.productdiv {
  padding: 30px;
  background-color: white;
  border-radius: 1.4rem 0 0 1.4rem;
  width: 50%;
  display: flex;
  justify-content: center;
  /* height:100px;  */
}
.product img {
  width: 100%;
  height: 30rem;
  margin-top: 30px;
  /* margin-bottom: 8px; */
  /* margin-left: 100px */
}
.productDetail {
  /* padding:30px; */
  margin-top: 20px;
  padding: 4rem;
  line-height: 30px;
  /* margin-left: 630px; */
  flex: 1;
  justify-content: space-between;
}
.promo-slider-container {
  max-width: 600px; 
  margin: auto;
}

.promo-card {
  border: 1px solid #ccc;
  background-color: white;
}

.promo-image {
  width: 100%;
  height: 170px;
  display: block;
}

.promo-content {
  padding: 10px;
}

.promo-description {
  font-size: 1.1rem;
  color: #F28638;
  text-align: center;
    margin-bottom: 7px;

    margin-top: 7px;
}
.lightbox-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  cursor: pointer;
}
.lightbox {
  position: relative;
  max-width: 52%;
  max-height: 52%;
  cursor: default;
}
.lightbox img {
  width: 100%;
  height: 100%;
}

.close-icon {
  position: absolute;
  top: 40px;
  right: 10px;
  font-size: 44px;
  line-height: 1;
  cursor: pointer;
  color: #000;
}
.promo-details span {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  color: #434F7B;
}
.productDetail h2 {
  margin-bottom: 7px;
  /* font-size: 30px; */
  font-weight: 800;
}
.productDetailp {
  color: #F17E2A;
  font-size: 24px;
  margin-bottom: 2px;
}
.productDetail div {
  /* display: inline; */
  width: 100%;
  /* height: 280px; */
  border-radius: 2rem;
}
.cart {
  cursor:pointer;
  width: 40px;
  height: 40px;
  padding-top: 5px;
  border-radius: 18px;
  background-color: #F17E2A;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  font-size: 26px;
  font-weight: 400;
  margin-top: 30px;
}

.no-items {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.aboutus {
  font-size: 14px;
}

.aboutus p {
  padding-bottom: 23px;
  color: #8C8C8C;
}
.about-span-color{
  color: #058205;
  font-size: 16px;
}
.about-span1 {
  color: #F17E2A;
  font-size: 16px;
}

.about-span2 {
  color: #434F7B;
  font-size: 16px;
  font-weight: 500;
}

.instagram {
  margin-right: 2px;
  margin-top: -8px;
}

.instagram img {
  width: 45px;
  height: auto;
}

.about-icons {
  size: 24px;
  display: flex;
  margin-left: 540px;
}

.whatsapp {
  color: #55CD6C;
  margin-right: 10px;
}

.fb {
  color: #2D307A;
  margin-right: 6px;
}

.insta {
  color: #F13963;
  margin-right: 10px;

}

.Login {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Login p {
  font-size: 20px;
  font-weight: 500;
  color: #5A5A5A;
}

.Login-span1 {
  color: #5A5A5A;
  margin-top: 20px;
  margin-left: 17.5rem;
  font-size: 14px;
}

.Login-span2 {
  color: #5A5A5A;
  margin-top: 2px;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.google-img {
  width: 30px;
  height: 30px;
}

.Logindiv {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 10px;
  border-radius: 10px;
  width: 11rem;
  align-items: center;
  margin-bottom: 20px;
}

.login-icon {
  color: #2D307A;
}

.Logindiv h6 {
  margin-top: 3px;
  font-size: 16px;
  margin-left: 25px;
  color: #414141;
}

.confirm-adress {
  justify-content: center;
  align-items: flex-end;
  background-color: #F17E2A;
  border-radius: 13px;
  outline: none;
  color: white;
  font-family: 'GilroyRegular';
  border: none;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  width: 27rem;
  font-weight: bold;
  display: flex;
}

.product_grid div :nth-child(2) {
  background: #E3E2EA;
}

.product_grid:nth-child(4) {
  background: #E3E2EA;
}

.checkoutInput {
  font-family: GilroyRegular;
  background-color: #F2F3F2;
  border: none;
  /* width: 750px; */
  height: 150px;
  border-radius: 24px;
  width: 100%;
  outline: none;
  padding-left: 15px;
  color: #333333;
  padding-top: 10px;
  font-size: 14px;
}
.checkoutInput::placeholder {
  padding-left: 5px;
  color: #7C7C7C;
  font-size: 14px;
  font-family: GilroyRegular;
}
.LoginInput {
  margin-top: 20px;
  outline: none;
  border: none;
  padding: 12px;
  border-radius: 10px;
  color: #F17E2A;
  width: 350px;
}

.LoginInput::placeholder {
  color: #7D7D7D;
  font-size: 16px;
  font-weight: bold;
}

.Login Button {
  background-color: #F17E2A;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  width: 150px;
  height: 60px;
  margin-top: 0px;
}

/* sidebar */
.sidebar-container {
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.sidebar-container.active {
  transform: translateX(0);
}

.sidebar-body {
  height: 100%;
  width: 550px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  background-image: url(/public/Images/Pattern.png);
}

.accordionItem.expanded {
  box-shadow: var(--shadow);
  border-radius: 6px;
}

.sidebar {
  display: flex;
  flex-direction: row;
  align-items: left;
}

.sidebar-components {
  display: flex;
  flex-direction: row;

  Button {
    background-color: #F17E2A;
    color: white;
    border-radius: 15px;
    font-size: 16px;
    font-weight: bold;
    font-family: GilroyRegular;
    border: none;
    width: 220px;
    height: 60px;
    margin-top: 10px;
  }

  img {
    margin-left: 10px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    background-color: #F17E2A;
    margin-right: 20px;

  }
}

.sidebar-components-icon {
  margin-top: 23px;
}

.sidebar-items {
  align-items: left;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding-left: 20px;

  div {
    display: flex;
    flex-direction: row;
  }

  span {
    margin-left: 30px;
    margin-top: 7px;
  }
}

.MdOutlineModeEdit-icon {
  background-color: #F17E2A;
  border-radius: 50px;
  color: white;
  padding: 4px;
}

.edit-Input {
  border-radius: 13px;
  color: #F17E2A;
  width: 435px;
  height: 45px;
  border: none;
  outline: none;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  margin-top: 1rem;
}

.edit-Input::placeholder {
  color: #8B93AE;
}

.edit-img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #F17E2A;
}

.editpopup-container {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;
}

.editpopup {
  background: #E2E1E9;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0;
  top: 20px;
  width: 500px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 30px;
  height: 585px;
  margin-top: -43px;
}

.editclose-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 24px;
  color: #464649;
  /* line-height: 1px; */
  text-align: center;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  display: flex;

}

.editpopup-heading {
  padding-top: 15px;
  align-items: center;
  display: flex;
  padding-bottom: 15px;
  font-size: 20px;
}

.editpopup-text {
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #5E5B60;
  font-weight: 600;
  white-space: nowrap;
}

.editpopup-form {
  display: flex;
  flex-direction: column;
}

.editlabel {
  margin-bottom: 5px;
}

.editpopup-input {
  margin-bottom: 15px;
  padding: 14px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #FFFFFF;
  width: 100%;
}

.editpopup-input::placeholder {
  color: #848484;
}

.editRow {
  width: 100%;
}

.editpopup-button {
  background-color: #6750A4;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  width: 100%;
  height: 40px;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
  /* width: 180px; */
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  display: block;
}

.avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.cart-count {
  position: absolute;
  text-align: center;
  top: -5px;
  bottom: 5px;
  right: -5px;
  background-color: #F17E2A;
  color: #ffffff;
  border-radius: 50%;
  width: 18px;
  height: 15px;
  font-size: 12px;
}

.payment-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-sizing: border-box;
  z-index: 1000;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  background: rgba(0, 0, 0, 0.5);
  /* transition: transform 0.5s ease-in-out; 
  transform: translateY(100%); */
}

.payment-popup {
  background-color: #EBE4F2;
  color: #434F7B;
  border-radius: 20px 20px 0px 0px;
  padding-left: 80px;
  padding-top: 45px;
  padding-right: 80px;
  width: 100%;
  max-width: 550px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  height: 40%;
  position: relative;
}
.payment-label {
  font-size: 1.1rem;
  cursor:pointer;
}
.line-after {
  width: 100%;
  margin: 10px auto;
  border: 1px solid #D3CCD9;
}

.payment-close {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 16px; 
  height: 20px; */
}

.payment-close-btn {
  /* text-align: center;
  vertical-align: middle;
  line-height: 20px; */
  padding-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2000;
  top: 20px;
  right: 77px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #EBE4F2;
  background-color: #F17E2A;
  border-radius: 50%;
}

.update {
  background-color: #F17E2A;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  width: 435px;
  height: 40px;
}

.sidebar-items hr {
  border: none;
  border-top: 1px solid #EFEFEF;
  margin: 5px 0;
  margin-top: 20px;
  width: 360px;
  margin-bottom: 15px;
}

.modal-backdrop {
  opacity: 0.5;
  background-color: #000;
}

.modal-content {
  background-color: #F2F3F2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 30rem;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.modal-dialog {
  margin: 0;
}

.modal-header {
  color: #000;
  border-bottom: none;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  border-top: none;
  padding-bottom: 20px;
}

.modal-footer button {
  margin-left: 19px;
}

.clock-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 1000;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  background: rgba(0, 0, 0, 0.5);
  /* transition: transform 0.5s ease-in-out; 
  transform: translateY(100%); */
}

.calendar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 1000;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  background: rgba(0, 0, 0, 0.5);
  /* transition: transform 0.5s ease-in-out; 
  transform: translateY(100%); */
}

.clock-popup {
  background-color: #FDF0E7;
  color: #333134;
  border-radius: 20px;
  padding-left: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  width: 100%;
  max-width: 290px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  height: fit-content;
  position: relative;
  /* justify-content: center; */
  flex-direction: column;
  display: flex;
}

.time-inputs {
  display: flex;
  flex-direction: row;
}

.period-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  /* border:1px solid black; */
  margin-left: 10px;
  border-radius: 10px;
}

.clock-input {
  border: none;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-top: 15px;
  outline: none;
  text-align: center;
  font-size: 46px;
}

.period-button1 {
  /* border:none;
  outline:none; */
  width: 50px;
  height: 35px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  background: none;
  border: 1px solid black;
}

.period-button2 {
  /* border:none;
  outline:none; */
  width: 50px;
  height: 35px;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
  background: none;
  border: 1px solid black;
}

.selected {
  background: #F18637;
}
.react-calendar {
  border: none !important;
  background-color: #FDF0E7 !important;
  font-family: GillroyRegular !important;
}
.calendar-popup {
  background-color: #FDF0E7;
  color: #E67A34;
  border-radius: 20px;
  padding-left: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  width: 100%;
  max-width: 390px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  height: fit-content;
  position: relative;
  /* justify-content: center; */
  flex-direction: column;
  display: flex;
}
.react-calendar__tile--active {
  background: #E67A34 !important;
  border-radius: 90px;
  color: black !important;
}
.react-calendar__tile:disabled {
  background-color: #FDF0E7 !important;
  color: #ababab;
}
.react-calendar__navigation button:disabled {
  background-color: #FDF0E7 !important;
}
.react-calendar__tile--now {
  background-color: #FDF0E7 ;
}
.react-calendar__tile--now {
  /* background: none !important; */
  /* color:#E67A34 !important; */
  border-radius: 25px;
  width: 40px;

  height: 40px;
  border: none !important;
}

.buttons-container {
  justify-content: flex-end;
  display: flex;
  gap: 1.5rem;
  align-items: last baseline;
}

.calendar_button1 {
  border: none;
  color: #E67A34;
  background-color: #FDF0E7;
  font-size: 14px;
  justify-self: flex-end;
  cursor: pointer;
}

.calendar_button2 {
  cursor: pointer;
  border: none;
  color: #E67A34;
  background-color: #FDF0E7;
  font-size: 14px;
  justify-self: flex-end;
}

.promo-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 1000;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  background: rgba(0, 0, 0, 0.5);
  /* transition: transform 0.5s ease-in-out; 
  transform: translateY(100%); */
}

.promo-popup {
  background-color: #FFFFFF;
  color: #E67A34;
  border-radius: 20px;
  padding-left: 30px;
  padding-top: 25px;
  padding-right: 30px;
  width: 100%;
  max-width: 390px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  height: auto;
  position: relative;
  /* justify-content: center; */
  flex-direction: column;
  display: flex;
}

.promo-label {
  font-size: 22px;
  margin-top: 55px;
  font-weight: 600;
  text-align: center !important;
}
.promo-label2 {
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
  text-wrap: wrap;
  /* margin-top: 3px; */
}
.promo-close {
  display: flex;
  justify-content: center;
}

.promo-tick-btn {
  display: flex;
    justify-content: center;
    align-items: center;
  background-color: #4FAD56;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
  position: absolute;
  z-index: 2000;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 60px;
  color: #FFFFFF;
  border-radius: 50%;
}
.promo-close-btn {
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  position: absolute;
  z-index: 2000;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 60px;
  color: #FFFFFF;
  background-color: #F17E2A;
  border-radius: 50%;
}
.alert-warning {
  background-color: #F7DBC6;
  color: #F18637;
  font-weight: bold;
  font-size: 13px;
  border-radius: 15px;
  padding: 15px;
  width: 100%;
}
.continue {
  padding-top: 3px;
  cursor:pointer;
  margin-bottom: 20px;
  background-color: #F17E2A;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  font-family: GilroyRegular;
  font-weight: bold;
  border: none;
  height: 40px;
  margin-top: 10px;
  width: 90px;
  align-self: center;
}

.cart-container {
  font-size: 14px;
  justify-content: center;
  align-content: center;
  /* display: flex; */
  margin-left: 13rem;
  margin-right: 12.6rem;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 20px;
  /* border-radius: 15px;  */
}

.cart-container-2 {
  margin-left: 13rem;
  margin-right: 12.6rem;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 20px;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/public/Images/Background.jpeg");
}

.cart-div1 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.image-div {
  background-color: white;
  width: 95px;
  height: 110px;
  border-radius: 20px;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .cart-image{
  width: 12%;
} */
.cart-items {
  display: flex;
  align-items: center;
  width: 86%;
  padding-left: 10px;
}

.image-div img {
  width: auto;
  height:60px;
}
.cart-div2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Item-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 10px;
}

.Item-info h5 {
  font-family: 'GilroyBold';
  color: #434F7B;
  font-size: 17px;
  white-space: nowrap;
  width: 630px;
  width: 80%;
}

.cross {
  color: #E4E4E4;
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.cart-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 570px; */
  gap: 30px;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;

  span {
    text-align: center;
    font-weight: 300;
    padding: 10px;
    width: 35px;
    color: #47527E;
  }
}

.cart-button-1 {
  padding: 7px;
  font-size: 28px;
  border-radius: 10px;
  cursor: pointer;
  /* margin-top: -5px; */
  background-color: white;
  color: #848484;
  border: 1px solid #F4F5F4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-button-2 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 28px;
  border-radius: 10px;
  cursor: pointer;
  color: #F17E2A;
  background-color: white;
  border: 1px solid #F4F5F4;
}

.cart-button-3 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 28px;
  border-radius: 10px;
  cursor: pointer;

  color: #F17E2A;
  background-color: white;
  border: 1px solid #F4F5F4;
}

.cart-quantity {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cart-price {
  color: #47527E;
  width: 20%;
  font-size: 20px;
  /* margin-right: 27px; */
  margin-top: 35px;
  white-space: nowrap;
  justify-content: flex-end;
  display: flex;
  /* overflow: hidden; 
  text-overflow: ellipsis; */
}

.cart-checkout {
  display: flex;
  flex-direction: column;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 22px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.cart-subtotal {
  font-family: 'GilroyBold';
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkout-button {
  font-family: gilroyRegular;
  color: #F17E2A;
  font-size: 18px;
  font-weight: bold;
  width: 191px;
  cursor:pointer;
  padding-top: 16px;
  padding-bottom: 13px;
  padding-left: 13px;
  padding-right: 13px;
  margin-top: 20px;
  background-color: white;
  border-radius: 15px;
  border: none;
}

.checkout-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.checkout-items h5 {
  /* width: 700px; */
  font-weight: 300;
  font-size: 14px;
  color: #959595;
  display: flex;
  justify-content: flex-start;
}
.checkout-items span {
  width: 30px;
  font-weight: 100;
  font-size: 14px;
  color: #4E5A83;
  display: flex;
  cursor:pointer;
  justify-content: flex-end;
}
.main {
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: row;
  width:100%;
}
.main-div {
   width:80%;
  padding-left: 15px;
  color: #333333;
  line-height: 22px;
}
.toggle-button {
  width: 50px;
  height: 25px;
  background-color:#F6BC92;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .toggle-button.active {
  background-color: orange;
} */

.toggle-button::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-button.active::before {
  transform: translateX(25px);
  background-color: #F28638;
}
.toggle-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.variationTitle {
  margin: 7px 0px;
  border: none;
  margin-right:7px;
  cursor: pointer;
  background-color: #f0f0f0; 
  padding: 10px 20px; 
  border-radius: 10px 20px;
  transition: background-color 0.3s; 
}

.variationTitle.active {
  background-color: #F6BC92; 
}
.variationPriceLineThrough {
  text-decoration:  2px line-through red;
  font-size:15px;
  margin-bottom: -2px;
}
.swiper-container-p {
  width: 100%;
  height: 100%;
}
.swiper-slide-p {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.swiper-slide-p img {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.content-p {
  margin-top: 20px;
  background-color: white;
}
.content-p h2 {
  display:flex;
  justify-content: center;
  font-size: 33px;
  color:#F28638;
  margin-bottom: 10px;
}
.promo-popup .content-p {
  background-color: #f3f3f3;
}
.promo-popup .promoImg {
  height:12rem;
}
.wallet-close {
  position: absolute;
  top: 13px;
  right: 25px;
  padding: 10px; 
  border-radius: 0 0 0 50%;
}

.wallet-close-btn {
  width: 22px;
  background-color: #a0a0a0; 
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
  position: absolute;
  z-index: 2000;
  color: #FFFFFF;
  border-radius: 50%;
}
.promo-popup .content-p h2 {
  font-size: 20px;
  font-size: 26px;
  font-family: GilroyHeavy;
  letter-spacing: 0.5px;
  font-weight: bold;
}
.promo-popup .content-p .promo-details > div {
  flex-direction: row;
    gap: 4px;
    font-family: 'GilroyBold';
    font-size: 14px;
    font-weight: bold;
    color:#495073;
}
.promo-popup .content-p .promo-details > div span{
  font-size: 17px;
}
.content-p span {
  font-size: 18px;
  /* color:black; */
  /* color: #666; */
}
.description-line {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.promo-details {
  display: flex;
  justify-content: space-around;
  color:black;
  flex-direction: column;
    align-items: center;
}

.promo-details div {
  display: flex;
  flex-direction: column;
}
.custom-swiper-slide{
  width:100% !important;
}
.promoImg{
  height: 20rem;
    width: 100%;

}

.promo-details div span {
  margin-bottom: 5px; 
}
.promo {
  justify-content: space-between;
  flex-direction: row;
  padding-left: 15px;
  width: 800px;
  color: #333333;
  display: flex;
}

.promo h5 {
  color: #F28638;
  font-size: 16px;
}
.checkout-icons {
  background-color: #F4A368;
  border-radius: 100%;
  width: 33px;
  height: 33px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.promo-input{
  outline:none;
  border:none;
  background-color: #FAFAFA;
  display: block;
  color: #333333;
  font-size: 16px; 
  font-family: GilroyRegular;
  margin-bottom: 5px;
}
.promo-input::placeholder {
  color: #333333;
  font-size: 16px; 
  font-family: GilroyRegular;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: #EEE8F4;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0;
  width: 360px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 30px;
  height: 535px;
  margin-top: -43px;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 30px;
  padding-top: 10px;
  cursor: pointer;
  font-size: 36px;
  color: #F17E2A;
}
.popup-heading {
  color: #F17E2A;
  padding-top: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 15px;
}

.popup-text {
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #5E5B60;
  font-weight: 600;
  white-space: nowrap;
}

.popup-form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

.popup-input {
  margin-bottom: 15px;
  padding: 14px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #F2F3F2;
}

.popup-button {
  font-family: 'GilroyRegular';
  background-color: #F17E2A;
  color: #fff;
  white-space: nowrap;
  width: 90px;
    height: 40px;
    padding: 13px;
  margin-top: 25px;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.autocomplete-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  max-width: 600px;
}
.autocomplete-options {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}
.autocomplete-option {
  padding: 8px;
  cursor: pointer;
}
.autocomplete-option:hover {
  background-color: #f0f0f0;
}

.map-search {
  outline: none;
  border: none;
  height: 40px;
  width: 450px;
    border-radius: 10px;
    margin-left: 15px;
    font-size:14px;
  font-family:GillroyRegular;
  padding-left: 15px;
}
.map-search ::placeholder{
   font-size:14px;
  font-family:GillroyRegular;
}
.react-calendar__month-view__weekdays {
    font-family:GillroyRegular !important;
}
.react-calendar *, .react-calendar *:before, .react-calendar *:after {
    font-family: 'GilroyRegular' !important;
}
.map-search-button {
  height: 40px;
  width: 70px;
  margin-left: 3px;
  border-radius: 9px;
  border: none
}
.accordion-button {
  /* box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1); */
  display: flex;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion-button>div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}

.accordion-button>div>span {
  color: #F17E2A;
}

.order-icons {
  display: flex;
  align-items: flex-start;
  color: #4A5073;
  gap: 20px;
  margin-top: 15px;
}

.panel-items {
  justify-content: space-between;
  display: flex;
  gap: auto;
  line-height: 23px;
  width: 100%;
}

.panel-items>:nth-child(1) {
  width: 50%;
}

.panel-items>:nth-child(2) {
  width: 20%;
  text-align: center;
}

.panel-items>:nth-child(3) {
  width: 30%;
  text-align: right;
}

.panel-div2 {
  justify-content: space-between;
  display: flex;
  gap: auto;
  color: #F17E2A;
  margin-top: 20px;
}

.accordion {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  background-image: url(/public/Images/Pattern.png);
  background-color: white;
  border-radius: 18px !important;
}

/* Additional Products */
.additional-products {
  display: flex;
  width: 80%;
}

.additional-products span {
  font-weight: 300;
  font-size: 14px;
  color: #939393;
  display: flex;

}

.additional-container {
  /* justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 13rem;
  margin-right: 12.6rem;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 20px; */
  width: 80%;
  justify-content: center;
  display: flex;
  margin-top: 50px;
  border-bottom: 0px;
  flex-direction: column;
  align-items: center;
}

.additional-container>div h4 {
  color: #F17E2A;
  font-size: 19px;
  padding-bottom: 30px;
}

.additional-container>div button {
  background-color: #F17E2A;
  outline: none;
  border: none;
  padding: 18px 0px;
  color: white;
  margin-bottom: 50px;
  font-weight: bold;
  width: 8rem;
  font-size: 15px;
  border-radius: 15px;
  margin-left: -16px;
  margin-bottom: 140px;
  font-family: GilroyRegular;
}

.additional-inputs {
  width: 80%;
  margin: 10px 0;
  display: flex;
}

.additional-inputs>div span {
  font-size: 14px;
  padding-left: 5px;
}

.additional-inputs:nth-child(2)>span {
  padding-left: 25px;

}

.additional-quantity {
  width: 100%;
  outline: none;
  border: none;
  padding: 14px;
  border-radius: 15px;
  color: #434F7B;
  background-color: #f2f3f2;
}

.additional-quantity:hover {
  background-color: #bbbcbb;
}

.error-message {
  padding-left: 5px;
  font-size: 13px;
  color: red;
   padding-top: 5px;
}

.view-items {
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
}
.view-items h4 {
  color: #434F7B;
  font-size: 18px;
  font-weight: bold;
  padding-left: 5px;
  font-weight: bold;
}
.additional-close {
  right: 1%;
  top: 6%;
  position: absolute;
  cursor:pointer;
  display: flex;
  width: 30px;
  height: 30px;
}
.items-additional {
  align-items: center;
  display: flex;
}

.items-additional span {
  font-size: 19px;
  color: #434F7B;
  font-weight: 900;
  padding-left: 17px;
}

.items-popup {
  margin-bottom: 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.items-popup span {
  font-size: 19px;
  color: #434F7B;
  font-weight: 900;
}

.items-popup-button {
  background-color: #F17E2A;
  outline: none;
  font-family:GilroyRegular;
  border: none;
  padding: 18px 0px;
  color: white;
  font-weight: bold;
  width: 8rem;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 15px;
  border-radius: 15px;
}

.view-items button {
  background-color: #F17E2A;
  outline: none;
  border: none;
font-family: 'GilroyRegular';
cursor:pointer;
  padding: 18px 0px;
  color: white;
  font-weight: bold;
  width: 8rem;
  font-size: 15px;
  border-radius: 15px;
}
/* OTPScreen  */
.otp-screen {
  text-align: left;
  width: 100%;
  max-width: 700px;
  padding: 20px;
}

.back-arrow {
  display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    width: 50px;
    height: 50px;
  cursor: pointer;
  margin-bottom: 10rem;
  background-color: #F8D5BC;
}

.otp-screen h1 {
  margin-bottom: 10px;
}

.otp-screen span {
  display: block;
  margin-bottom: 20px;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width:40%;
  margin-bottom: 50px;
}
.otp-button{
  display: flex;
  justify-content: center;
}
.otp-input {
  border-radius: 20px;
  height: 60px;
  color:#F17E2A;
  border:1px solid #F17E2A;
  width: 60px;
  /* width: calc(100% / 6 - 10px); */
  padding: 10px;
  font-size: 16px;
  text-align: center;
  font-size:26px;
  font-weight:bold;
}
.otp-input:focus {
  outline: none;
  color:#515C85;
  font-size:26px;
  font-weight:bold;
  border: 2px solid #515C85; 
}

.submit-btn {
  display: block;
  width: 100px;
  background-color: #F17E2A;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-weight: 900;
  border: none;
  cursor: pointer;
  text-align: center;
}
.order-image {
  width: 200px; 
  height: auto; 
  margin-bottom: 20px; 
  align-self: center;
}

.order-text {
  margin-top: 20px; 
  font-size: 18px;
}
.place-order{
 display: flex;
 flex-direction: column;
}
.order-placed-btn {
  margin-top: 20px;
  width: 22rem;
  padding: 20px;
  background-color: #F28638;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  font-size:15px;
  font-family:GilroyRegular;
  font-weight: bold;
  align-self: center;
}
.center-content{
display: flex;
justify-content: center;
}
.back-btn {
  align-self: center;
  font-size:15px;
  font-family:GilroyRegular;
  font-weight: bold;
  border-radius: 15px;
  margin-top: 20px; 
  margin-bottom: 20px; 
  width: 22rem;
  padding: 20px;
  background-color: white;
  color: #F28638;
  text-decoration: none;
}
.image-div-order{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.image-div-order h2{
  text-align:center !important;
}
/* privacy-policy */

.privacy-policy {
  font-family: 'GilroyRegular';
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

.privacy-policy h1 {
  color: #333; 
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}
.privacy-policy p {
  font-size: 14px;
  line-height: 1.6;
  margin-top: 10px;
}
.privacy-policy h3 {
  color: #333; 
  margin-top: 20px;
  margin-bottom: 10px;
}
.privacy-policy h4{
  margin-bottom: 8px;
  margin-top: 8px;
}
.privacy-policy h6{
  margin-top:6px;
  margin-bottom: 6px;
}
.privacy-policy ul {
  margin-top: 10px;
}

.privacy-policy li {
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 1.5;
   margin-left: 20px;
}

.privacy-policy li strong {
  color: #333;
}

.privacy-policy .interpretation, 
.privacy-policy .definitions, 
.privacy-policy .privacy-policy-text {
  margin-top: 20px;
}

@media only screen and (max-width: 480px) {
  .order-text{
    font-size:16px;
  }
  .promoImg{
    height: 10rem;
      width: 100%;
  }
  .content-p h2 {
    font-size: 23px;
  }
  .content-p span {
    font-size: 15px;
}
  /* .swiperslide {
    width: 65% !important; 
  } */
  .image-div {
    width: 70px;
    height: 120px;
  }

  .productDetail h3 {
    font-size: 24px;
    font-weight: 700;
  }
  .productDetail div {
    height: 80px;
  }
  .exclusive_product {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 13px;
  }

  .products_grid_Cat {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  .cart-div2 {
    /* padding-left: 10px; */
  }

  .cart-container-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .products_grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }
  .products_grid_item {
    background: #F9E8DB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 8em;
  }

  .products_grid_item:hover {
    cursor: pointer;
  }

  .heading-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading-box a {
    text-decoration: none;
  }
  .Exclusive_itemp {
    font-size: 16px;
  }
  .sidebar-body {
    padding-left: 15px;
    padding-right: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
}
  .see-all {
    background-color: #F8F8FF;
    color: midnightblue;
    display: block;
    border: none;
    cursor: pointer;
  }

  .products_grid_item img {
    padding-bottom: 10px;
    max-width: 60px;
    height: 60px;
    padding-top: 5px;
  }

  .products_grid_item h6 {
    font-size: 14px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .popular-exclusive {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }
  .image-div img {
    width: auto;
    height: 40px;
  }
  .quantity-buttons {
    width: 160px;
    padding: 5px;
  }
  .Exclusive {
    /* width: 154px; */
    border-radius: 25px;
  }
  .Exclusive_item {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 17em;
    /* width: 11em; */
    border-radius: 25px;
    border: 1px solid gainsboro;
  }

  .exclusive_product {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
  }

  .Exclusive_p {
    /* width: 170px; */
  }

  .Exclusive_item_p {
    min-height: 17em;
    width: 10.5em;
  }

  .I_prices {
    padding-left: 80px;
    padding-right: 10px;
  }

  .Exclusive_old_I {
    margin-left: -59px;
  }

  .Exclusive_price-new_I {
    margin-left: -60px;
  }

  /* .products_grid_item {
    min-height: 7em;
  } */

  .addtocard {
    margin-top: -37px;
  }
  .Exclusive_old {
    margin-left: 4px;
  }
  .Exclusive_price-new {
    margin-left: 1px;
  }
 .cart-subtotal {
    font-family: 'GilroyBold';
    font-size: 18px;
  }
  .swiper-pagination-bullet {
    background-color: #e3e3e3;
    width: 8px;
    height: 8px;
    margin: 0 4px;
  }

  /* .swiper-container{
    width: 250px;
  } */

  /* .swiper-pagination-bullet::active {
    background-color: red;
  } */

  .swiperImg {
    height: 8rem;
    width: 100%;
    /* background-size: cover; */
    /* border-radius: 9px; */
  }

  .product {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .product img {
    width: 100%;
    height: 15rem;
    margin-top: 30px;
    /* margin-left: 50px; */
  }

  .productdiv {
    padding: 30px;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 1.4rem 1.4rem 0 0;
  }

  .productDetail {
    margin-top: 5px;
    padding: 2rem;
    flex: 1 1;
  }

  .cart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    margin-top: 30px;
  }

  .product-buttons {
    display: flex;
    padding: 5px;
    /* padding-bottom: 15px;
    padding-top: 8rem; */
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  .products_grid_Cat {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .subcategory {
    margin-bottom: 0.5rem;
  }
  .promoImg{
    height: 15rem;
      width: 100%;
  }
  .content-p span {
    font-size: 17px;
}
  .Item-info h5 {
    white-space: pre-wrap;
  }
  .productDetail h3 {
    margin-bottom: 7px;
    font-size: 26px;
    font-weight: 700;
  }

  .popular-exclusive {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  .cart-container {
    margin-left: 0;
    margin-right: 0;
  }

  .cart-container-2 {
    margin-left: 0;
    margin-right: 0;
  }

  /* .swiperslide {
    width: 50% !important;
} */
  .products_grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  /* .products_grid_item {
    min-height: 9em;
  } */
  .Exclusive {
    /* width: 224px; */
  }

  .exclusive_product {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 13px;
  }

  .Exclusive_item {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 17em;
    /* width: 13em; */
    border-radius: 25px;
    border: 1px solid gainsboro;
  }

  .Exclusive_item {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .Exclusive_old {
    margin-left: -4px;
  }

  .Exclusive_price-new {
    margin-left: -7px;
  }

  .addtocard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
  }

  .products_grid_item {
    background: #F9E8DB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 10em;
    /* width: 15rem; */
  }

  

  .products_grid_item h6 {
    font-size: 16px;
  }
  .products_grid_item img {
    padding-bottom: 10px;
    max-width: 80px;
    height: 80px;
  }
  .cart-items {
    width: 83%;
    padding-left: 5px;
  }
  /* .cart-image {
  width: 15%;
} */
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .productDetail h3 {
    font-size: 28px;
    font-weight: 800;
  }

  .cart-container {
    margin-left: 0;
    margin-right: 0;
  }

  .popular-exclusive {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }

  .exclusive_product {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 13px;
  }

  .products_grid_item h6 {
    font-size: 18px;
  }

  .products_grid_item img {
    max-width: 80px;
    height: 80px;
  }

  .cart-container-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .products_grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
  }

  /* .swiperslide {
    width: 35% !important; 
  } */
  /* .products_grid_Cat {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  } */
  .products_grid_item {
    background: #F9E8DB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 12em;
    /* width: 15rem; */
  }

  .special_bg {
    background: #DFE1E7;
  }

  .products_grid_Cat {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
  }

  /* .cart-image{
  width: 10%;
} */
  .cart-items {
    width: 90%;
    padding-left: 20px;
  }
}