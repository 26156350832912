.navbar-n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
  /* background-color: #f8f9fa; */
  position: relative;
  padding-top: 7px;

}
.video-thumbnail {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.video-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.video-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.video-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.video-close:hover,
.video-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.display-class{
  display:none;
}
.navbar-brand {
  margin-left: -25px;
  justify-content: flex-start;
  display: flex;
}
.additional-links {
  display: none; 
}
.navbar-toggler {
  display:block;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 1.5rem;
  color: #4D5882;
}

.navbar-menu {
  /* position: absolute; */
  /* top: 100%;
  left: 0;
  right: 0; */
  width: 100%;
  background-color: #FAFAFA;
  /* overflow: hidden; */
  transition: all 0.5s ease-out;
  max-height: 0;
}

.navbar-menu.expanded {
  max-height: 500px; /* Adjust as necessary */
}

.navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0;
  padding: 1rem;
}

.nav-item {
  margin-bottom: 1rem;
}
#exclusive-offers .swiper-button-prev, #exclusive-offers .swiper-button-next {
  position: absolute;
  top: -130px; 
  width: 30px;
  height: 30px;
  z-index: 10;
  background-size: cover;
  cursor: pointer;
}
/* .swiper-button-prev, .swiper-button-next {
  background-size: contain;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 133%;
  transform: translateY(-50%);
  z-index: 10;
} */

.swiper-button-prev {
  left: 10px;
  top: -139px;
}

.swiper-button-next {
  right: 10px;
  top: -139px;
}
.nav-link {
  text-decoration: none;
  color: #495178;
  gap:1rem;
  display: flex;

}
.download-app {
  display: inline-block;
  background-color: #F9851C;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  text-align: center;
  margin-top: 2px;
  border-radius: 40px;
  width: 150px;
}
.nav-link.disabled {
  color: #ccc;
}
.anchor-style{
  font-family: 'GilroyLight';
  /* font-weight: bold; */
  display: flex;
  color:#434F7B;
  /* color: white; */
  text-decoration: none;
}
 /* Mart CSS */
.marts_grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin-bottom: 1rem; 
}
.mart-items{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.marts_grid_item{
    background: #F9E8DB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    min-height: 6rem;
   padding: 15px 15px;
  }
  .marts_grid > a:nth-child(2) > div > .marts_grid_item,
  .marts_grid > a:nth-child(4) > div > .marts_grid_item {
    background-color: #A2DEA2; 
    color:white;
  }
  .marts_grid > a:nth-child(2) > div > .marts_grid_item h5,
  .marts_grid > a:nth-child(4) > div > .marts_grid_item h5 {
    color: white;
}
.marts_grid > a:nth-child(2) > div > .marts_grid_item span,
  .marts_grid > a:nth-child(4) > div > .marts_grid_item span {
    color: #4D5882;
}
.marts_grid > a:nth-child(2) > div > .marts_grid_item .mart {
  background-color: white;
  color:#4D5882;
}
  .products_grid > a:nth-child(even) > div.products_grid_item {
    background-color: #DFE1E7;
  }
  .marts_grid_item h5 {
    margin-left: 15px; 
    font-size: 1.2rem;
    font-weight: 900;
    color:#4D5882;
    margin-bottom: 2px;
  }
  .marts_grid_item span {
   color:#F9851C;
   font-size: 13px;
   margin-left: 13px; 
   font-weight:600;
  }
  .mart{
      color:#5C5772;
      background-color: #F8B57F;
      border-radius: 25px;
      width:40px;
      height:40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
  }
  .mart img{
    width: 25px;
    height: 25px;
  }
.mart-logo{
    width: 200px;
    height: auto;
    /* padding-top: 10px; */
    margin-right: 245px; 
}
.m-navbar{
    justify-content: space-around;
    align-items: center;
    display: flex;
}
.m-section1{
    display: flex;
    flex-direction: column;
}
.mart-location{
   line-height: 20px;
    margin-left: 17px;
    margin-top: 2px;
    font-size: 13px;
    font-weight: 100;
}
.m-central
  {
    /* margin-top: 25px; */
    /* margin-left: 90px;    */
    display: flex;
    position: relative;
    justify-content: center;
    width:280px;
    align-items: center;
    flex-direction: row;     

  }
  .m-location {
    width: 1100px;
    height: 25px;
    margin-left: 15px;
    color: darkblue;
    border: 0 solid lightgray;
    border-radius: 5px;
    outline: none;
    background-color: #FAFAFA;
}
  /* Landing Page CSS */
.navbar-components{
    width:65%;
    display:flex;
    gap:1rem;
    justify-content: flex-end;
}
.img-navbar{
  /* width: 120px;
  height: 40px;
  margin-top:1px; */
  cursor: pointer;
}
  .m-control{
    background-color:#F2F3F2 !important;
    color:darkblue !important;
    /* margin-left: -90px; */
    width: 100%;
    padding-left: 24px;
    height:50px;
    /* margin-left: 156px; */
    /* line-height: 1.5px; */
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 25px;
    /* padding-bottom:13px;
    padding-top: 13px;
    padding-right: 13px;
    padding-left: 35px;
    margin-top: 10px; */
    justify-content: center;
    align-items: center;
    outline: none;
    /* margin-bottom: 2rem; */
  }
  .m-control:hover{
    background-color: #E8E9E8;
  }
  .m-control ::placeholder{
    color: #7C7C7C;
    /* align-items: center; */
    padding-top: 20px;
    margin-top: 5px;
    margin-left: 40px;
    padding-left: 40px;
  }
  .m-icon {
    /* size: 16px; */
    transform:translateY(-50%) ;
    color:#44507C; 
    position: absolute;
    cursor: pointer;
    top: 20px;
    /* right: 15px; */
    width:20px;
    /* height:10%; */
    border-radius:50%;
    border:none;
    background: none;
    /* margin-right: 535px;
    margin-top: -2px; */
    font-size: 17px;
  }
  .search-icon-div{
    background-color: #929292;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.con-comp{
  font-family: 'GilroyBold';
    font-weight: bold;
    display: flex;
    color:white;
    gap:2rem;
    padding:15px 0px;
    margin-top: 10px;
    font-size:19px;
}

/* Download app */
.download-btns{
    gap:1rem;
    display: flex;
}
.download-btns .img {
    width: 140px;
    height: auto;
    cursor: pointer;
    border-radius: 6px;
}
.download-comp{
    align-items:center;
    display: flex;
    flex-wrap: wrap;
    color:white;
    gap:5rem;
    padding:36px 0px;
    justify-content: space-between;
    font-size: 23px;
}
/* Exclusive */
.dropdown {
    position: relative;
    display: inline-block;
  }
    .dropbtn {
    background-color: #F2F3F2;
    color: black;
    padding: 6px 15px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius:50px;
    width: 18rem;
    height:2.7rem;
    text-align:left;
  }
    .dropdown-content {
    width: 18rem;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .arrow {
    transition: transform 0.3s ease;
    position: absolute;
    right: 15px;
    top:12px;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #F2F3F2;
  }
  .addtocard-LP{
    width: 42px;
    height: 50px;
    border-radius: 15px;
    background-color: #F17E2A;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 160.5px; */
    margin-top: -40px;
    margin-left: auto;
  }
  .addtocard-LP-p{
    text-align: center;
    font-family: 'GILROYBOLD';
    font-size: 11px;
    font-weight: bold;
  }

 /* Why you Choose Us */
.chooseus{
  /* justify-content: center; */
  height: auto;
  /* position: absolute;
  top:-80px;
  width:85%; */
}
.ChooseUs-Section{
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative;
    height: 15rem;
    background-image: url(/public/Images/Group.svg);
    background-size: cover;
    background-repeat: no-repeat; */
}
.choose-us-heading{
  display: flex;
  justify-content: center;
}
.choose-us-heading h2 {
  margin-bottom: 20px;
  font-family: GilroyRegular;
  color:green;
}
.download-comp h4{
  font-family: GilroyRegular;
}
.choose-us-heading h4 {
  font-family: GilroyExtraBold;
  font-size: 20px;
  color:#D5D9E2;
  text-align: center;
}
.choose-us-heading h1 {
  /* margin-bottom: 20px; */
  font-size: 22px;
  color:#F9851C;
  font-family: GilroyExtraBold;
}
.choose-us-items {
  display: flex;
  justify-content: center; 
  gap: 20px; 
}
.item {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 20%; 
  padding: 20px; 
  box-sizing: border-box; 
  border-radius: 10px;
  color: #fff; 
}
.choose-us-items .item:nth-child(1) {
  background-color: #4D5882;
  color: white; 
}
.choose-us-items .item:nth-child(2) {
  background-color: #F17E2A;
}
.choose-us-items .item:nth-child(2) h6 {
  color:black;
}
.choose-us-items .item:nth-child(2) span {
  color:white;
}
.choose-us-items .item:nth-child(2) {
  background-color: #F17E2A;
  color: white; 
}
.choose-us-items .item:nth-child(3) {
  background-color: #fbecc5;
  color: black; 
}
.item h6 {
  font-size: 14px;
  margin: 0 0 10px 0; 
  /* color: inherit;  */
  font-weight: bold;
  font-family: 'GilroyBold';
}
.item span {
  font-size: small;
  display: block; 
  color: inherit; 
}
.overflow-menu{
  display: none;
}
.mob-dropdown{
  display: none;
  margin-top:10px;
}
/* why choose us (images section) */
.ChooseUs-SectionImg{
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(/public/Images/Group.svg); */
  background-size: cover;
  background-repeat: no-repeat;
}
.about-us-text{
  font-size:18px;
  color:rgb(124, 128, 129)
}
.chooseusImg{
  justify-content: center;
  /* height: auto; */
  /* position: absolute; */
  /* top:-80px;
  width:85%; */
}
.about-us-tagline{
  text-align: center;
  line-height: 1.6; 
}
.choose-us-tagline{
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6; 
}
.choose-us-tagline p{
  font-size:18px;
}
.features {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  gap: 2rem;
}
.feature-item {
  padding-bottom: 7px;
  text-align: center;
  border: 1.5px solid #F2F3F2;
  width: 80%;
}
.feature-item img {
  width: 100%;
  margin-bottom: 1rem;
}
.feature-item video {
  width: 105%;
  margin-bottom: 1rem;
}
.feature-item h6 {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color:green;
}
.feature-item p {
  font-size: 13px;
  /* font-size: 0.875rem; */
  padding-left:5px;
  padding-right:5px;
}
.final-call {
  margin-top: 2rem;
  font-size: 1.5rem;
}
.cancel-order-promo{
  display:flex;
  justify-content: flex-end;
  gap:5px;

}
.cancel-order-label{
   color:black;
   font-size:18px;
}
.cancel-order-promo button{
  background-color: white;
  color:#F9851C;
  height: 28px;
  width: 20%;
}
@media only screen and (max-width: 1064px) {
  .marts_grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 1rem; 
  }
  /* .ChooseUs-Section{
    width:45vh;
  } */
}
@media only screen and (max-width: 654px) {
  .marts_grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 1rem; 
  }
  .choose-us-items {
    flex-direction: column;
}
.item{
      width: 100%;
}
.ChooseUs-Section{
      /* height: 80vh; */
 }

}
@media only screen and (max-width: 924px) {
.download-comp{
  gap:1rem;
  font-size: 16px;
 }
}
@media only screen and (max-width: 656px) {
  .download-comp{
    flex-direction: column;
    gap:2rem;
    font-size: 14px;
    text-align: center;
   }
   .download-btns{
    /* gap:1rem;
    display: flex; */
    justify-content: center;
}
.choose-us-tagline p {
  font-size: 18px;
}
}
@media only screen and (max-width:768px) { 
  .display-class{
    display:block;
  }
  .navbar-menu.collapsed {
    display: none; 
}
.navbar-menu.expanded {
    display: block; 
}

.navbar-menu.expanded .additional-links {
    display: block; /* Show when expanded on small screens */
            padding-bottom: 20px;
    /* Add any necessary styling here */
}

.dropdown1, .img-navbar {
    display: none; 
}
.expanded-container{
    display:flex;
    padding-top: 10px;
    gap: 1rem;
    /* background: #4D5882; */
    color:white;
    flex-direction: column;
}
.additional-links {
    display: block; 
}
.con-comp{
   display: none;
}
.img-navbar {
  display: none;
}
.navbar-components {
  gap: 0;
}
/* why choose us images */
.features {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 2rem;
}
.feature-item {
  width: 100%;
}
.feature-item img {
  height: auto;
}
.choose-us-tagline p {
  font-size: 19px;
}

}
@media only screen and (max-width:600px) {
.navbar-components{
    display: none;
 }
 .overflow-menu{
  display: block;
  position: relative;
}
 .over-menu{
  color:#434F7B;
  display: flex;
  justify-content: flex-end;
}
.overflow-containers{
  position: absolute;
  color:#434F7B;
  background-color: white;
  right:0;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  gap: 2rem;
  height: 20rem;
  width: 14rem;
  transition:right  0.5s ease-in-out;
}
}
@media only screen and (max-width:468px) {
 .mob-dropdown{
  display: block;
  width:100%;
  height:50px
 }
 .choose-us-heading h2 {
  margin-bottom: 18px;
}
 .dropdown {
  width:100%;
}
.dropbtn {
  width: 100%;
}
.dropdown-content {
  width: 100%;
}
.ChooseUs-Section{
  /* height: 85vh; */
 }
 .feature-item img {
  height: auto;
}
.choose-us-tagline p {
  font-size: 14px;
}
.dropdown{
  display: block; 
}
}
@media (min-width: 768px) {
  .navbar-menu {
      position: static;
      max-height: none;
  }

  .navbar-nav {
      display: flex;
      margin: 0;
  }

  .nav-item {
      margin-left: 1rem;
      margin-bottom: 0;
  }
  .navbar-toggler {
      display:none;     
  }

  .navbar-menu.collapsed {
      display: block;
  }
  .dropdown1 {
      position: relative;
      display: inline-block;
  }
  .dropbtn1 {
      background-color: #F2F3F2;
      color: black;
      padding: 6px 15px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      border-radius:50px;
      width: 18rem;
      height:2.7rem;
      text-align:left;
  }
  .dropdown-content1 {
      width: 18rem;
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1000;
  }
  .dropdown-content1 a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
  }
  .arrow {
      transition: transform 0.3s ease;
      position: absolute;
      right: 15px;
      top:12px;
  } 
  .dropdown-content1 a:hover 
  {
    background-color: #f1f1f1
  }
  .dropdown1:hover .dropdown-content1 {
      display: block;
  }
  .dropdown1:hover .dropbtn1 {
      background-color: #F2F3F2;
  }
  .img-navbar{
      /* width: 120px;
      height: 40px;
      margin-top:1px; */
      cursor: pointer;
  }
  .additional-links {
      display: none;
  }

  .dropdown1, .img-navbar {
      display: block; 
  }
}